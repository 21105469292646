<!--销售清单-->
<template>
    <div class="mainBox">
		<!--日期筛选-->
		<div class="handleBox overviewTime flex flex-jc-fe flex-ai-c">
			<el-radio-group v-model="dateRadio" style="margin-right:10px">
				<el-radio label="week">周</el-radio>
				<el-radio label="month">月</el-radio>
				<el-radio label="year">年</el-radio>
			</el-radio-group>
		
			<el-date-picker v-show="dateRadio == 'year'" v-model="inputyear" size="mini" type="year" value-format="yyyy" format="yyyy年" placeholder="选择年" @change="getList()">
			</el-date-picker>
			<el-date-picker v-show="dateRadio == 'month'" v-model="inputmonth" size="mini" type="month" value-format="yyyy-MM-dd" format="yyyy年MM月" placeholder="选择月" @change="getList()">
			</el-date-picker>
			<el-date-picker v-show="dateRadio == 'week'" v-model="inputweek" size="mini" type="week" value-format="yyyy-MM-dd" format="yyyy 第 WW 周" placeholder="选择周" @change="getList()">
			</el-date-picker>
			<el-select v-model="houseId" placeholder="选择门店筛选" size="mini" clearable class="selectWidth" @change="curr=1;getList()" style="margin-left: 7px;">
				<el-option v-for="item in houseList" :label="item.name" :value="item.id" :key="item.id"></el-option>
			</el-select>
		</div>
        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column label="商品图片"  show-overflow-tooltip >
                    <template slot-scope="scope">
                        <el-image class="tableImage" :src="domain + scope.row.product_pic" fit="cover" :preview-src-list="[domain + scope.row.product_pic]"></el-image>
                    </template>
                </el-table-column>
				<el-table-column prop="product_name" label="商品名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="attr_name" label="商品规格" show-overflow-tooltip></el-table-column>
				<el-table-column prop="num" label="销售量" show-overflow-tooltip align="center"></el-table-column>
                <el-table-column prop="total" label="销售额(元)"  show-overflow-tooltip align="center"></el-table-column>
                <!-- <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column> -->
            </el-table>
			
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import {salesListApi}  from '@/api/overview.js';
	import { getHouseNameApi } from '@/api/house.js';
    export default {
        data() {
            return {
                list:[], //列表
				houseList:[],
				
				selectDate: null,
				dateRadio: 'month', //年月周日-默认日
                inputyear: '',
                inputmonth: '',
                inputweek: '',
                inputDay: '',
                type: '',
				houseId:'',//门店id

                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
            }
        },
        mounted() {
            this.init();
			this.getHouseName();
        },
		watch: {
			'dateRadio': function() {
				var myDate = new Date();
				switch (this.dateRadio) {
					case 'year':
						this.inputyear = String(myDate.getFullYear());
						break;
					case 'month':
						this.inputmonth = (new Date(myDate.getFullYear(), myDate.getMonth(), 1))
						break;
					case 'week':
						this.inputweek = this.dataFormat(new Date(myDate.getFullYear(), myDate.getMonth(), (myDate.getDate() - myDate.getDay()) + 1));
						break;
					case 'day':
						this.inputDay = this.dataFormat(new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate()));
						break;
					default:
						break;
				}
				this.getList();
			}
		},
        methods: {
			init() {
				let _this = this;
				this.$nextTick(() => {
					if (_this.dateRadio == 'month') {
						_this.inputmonth = _this.dataFormat(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
						_this.getList();
					}
				})
			},
            //销售清单列表
            getList: function(){
				//筛选日期
				this.selectDate = this.dateRadio == 'day' ? this.inputDay : this.dateRadio == 'week' ? this.inputweek : this.dateRadio == 'month' ? this.inputmonth : this.inputyear;
				//筛选类型
				this.type = this.dateRadio == 'day' ? 4 : this.dateRadio == 'week' ? 1 : this.dateRadio == 'month' ? 2 : 3;
                salesListApi({
                    curr:this.curr,
                    row:this.row,
					type:this.type,
					date:this.selectDate,
					warehouse_id:this.houseId
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                })
            },
			//获取各店名称
			getHouseName: function(){
				getHouseNameApi().then(response=>{
					this.houseList = response;
				})
			},

            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
			//时间转换
			dataFormat: function(time) {
				return `${time.getFullYear()}-${time.getMonth() + 1 >= 10 ? (time.getMonth() + 1) : '0' + (time.getMonth() + 1)}-${time.getDate() >= 10 ? time.getDate() : '0' + time.getDate()}`;
			}
        }
    }
</script>
